.main{
  margin-top:50px;
  margin-left:100px;
  margin-right:100px;
}

.qr-code-container {
  text-align: center;
 
  font-family: 'Arial', sans-serif;
  float: left;
  width: 50%;
  border-right:1px solid #ccc;
  height: 550px;
  padding:10px;
 
}

.qr-code-seperate{
  text-align: center;
  float:left;
  margin-top:50px;
  width: 50%;
  height: 500px;
  
  

}

.title {
  font-size: 2.5em;
  color: #333;
}

.tabs {
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.tab.active {
  background-color: #007bff;
  color: #fff;
}

.tab:hover {
  background-color: #4f96e3;
  color: #fff;
}

.input-field {
  padding: 10px;
  width: 300px;
  margin-bottom: 20px;
  font-size: 1em;
  border: 2px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.input-field:focus {
  outline: none;
  border-color: #007bff;
}

.generate-btn {
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.generate-btn:hover {
  background-color: #0056b3;
}

.qr-code {
  position: relative;
  display: inline-block;
  margin-top: 20px;
}

.qr-code-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  border-radius: 10%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}


@media only screen and (max-width: 600px) {
  .qr-code-container {
    float: none;
    border:none;
    width: 100%;
    height: 200px;
  }
  .qr-code-seperate{
    float: none;
    width: 100%;
    
  }
  .title {
    font-size: 1.5em;
    color: #333;
  }

}